<template>
  <div class="header py-0 shadow bg-white mt-3">
    <div class="container-fluid">
      <div class="header-body">
        <div class="row py-3">
          <nav aria-label="breadcrumb" class="ml-2">
            <ol class="breadcrumb breadcrumb-links">
              <li class="breadcrumb-item">
                <a href="#"><i class="fas fa-link"></i></a>
              </li>
              <li class="breadcrumb-item"><a href="#"></a></li>
              Charts Analytics
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-4 card shadow w-100 p-4 text-center">
    <loader v-if="!this.data_loaded_cashout && !this.data_loaded_in" />

    <div class="row" v-if="this.data_loaded_cashout && this.data_loaded_in">
      <div class="col-md-4">
        <div class="card card-stats">
          <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5
                  class="card-title text-uppercase text-muted mb-3 txt-[#154462]"
                >
                  Current Balance
                </h5>
                <span class="h1 font-weight-bold mb-0">{{
                  format_currency(total_cashin - total_cashout)
                }}</span>
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-orange text-white rounded-circle shadow"
                >
                  <i class="ni ni-money-coins"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-stats">
          <!-- Card body -->
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5
                  class="card-title text-uppercase text-muted mb-3 txt-[#154462]"
                >
                  Total Cashin
                </h5>
                <span class="h1 font-weight-bold mb-0">{{ format_currency(total_cashin) }}</span>
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-[#154462] text-white rounded-circle shadow"
                >
                  <i class="ni ni-collection"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5
                  class="card-title text-uppercase text-muted mb-3 text-[#154462]"
                >
                  Total Cashout
                </h5>
                <span class="h1 font-weight-bold mb-0">{{
                  format_currency(total_cashout)
                }}</span>
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-danger text-white rounded-circle shadow"
                >
                  <i class="ni ni-chart-pie-35"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" v-if="this.data_loaded_cashout && this.data_loaded_in">
      <div class="col-md-6">
        <div class="mt-1 card shadow w-100 p-1 text-center">
          <GChart type="BarChart" :data="chartData" :options="chartOptions" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mt-1 card shadow w-100 p-1 text-center">
          <GChart type="PieChart" :data="chartData" :options="chartOptions" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { GChart } from "vue-google-charts";
import { collection, getDocs, where, query } from "firebase/firestore";
import { db } from "../../firebase";
import IndependentLoader from "../../components/IndependentDefaultLoader";
import { GeneralMixin } from '@/mixins/general_mixins';

export default {
  mixins: [GeneralMixin],
  name: "Home",
  components: {
    GChart,
    loader: IndependentLoader,
  },
  data() {
    return {
      data_loaded_cashout: false,
      data_loaded_in: false,

      total_cashout: 0,
      total_cashin: 0,
      chartData: [
        ["Task", "rate"],
        ["CashIn", 0],
        ["CashOut", 0],
      ],
      chartOptions: {
        title: "CashFlow",
        width: 500,
        height: 400,
        is3D: true,
        colors: ["#154462", "#EE9F9B"],
      },
    };
  },

  methods: {
    formatNumber(number) {
      return number.toLocaleString(); // Automatically adds thousands separator
    },
    fetch_cash_out: async function () {
      this.data_loaded_cashout = false;
      var q;
      if (this.$store.getters.getUserGroup === "superadmin") {
        q = query(collection(db, `cashflow`), where("flow", "==", "cashout"));
      } else {
        q = query(
          collection(db, `cashflow`),
          where("flow", "==", "cashout"),
          where("user_id", "==", this.$store.getters.getUserId)
        );
      }
      const snapShots = await getDocs(q);
      snapShots.forEach((document) => {
        let service = document.data();
        let amount = service["amount"];

        // Check if amount is a string and contains commas
        if (typeof amount === "string" && amount.includes(",")) {
          amount = parseInt(amount.replace(/,/g, ""));
        } else {
          amount = parseInt(amount);
        }

        this.total_cashout += amount;
      });
      this.chartData[2][1] = this.total_cashout;
      this.data_loaded_cashout = true;
    },
    fetch_cash_in: async function () {
      this.data_loaded_in = false;
      var q;
      if (this.$store.getters.getUserGroup === "superadmin") {
        q = query(collection(db, `cashflow`), where("flow", "==", "cashin"));
      } else {
        q = query(
          collection(db, `cashflow`),
          where("flow", "==", "cashin"),
          where("user_id", "==", this.$store.getters.getUserId)
        );
      }

      const snapShots = await getDocs(q);

      snapShots.forEach((document) => {
        let service = document.data();

        let amount = service["amount"];

        // Check if amount is a string and contains commas
        if (typeof amount === "string" && amount.includes(",")) {
          amount = parseInt(amount.replace(/,/g, ""));
        } else {
          amount = parseInt(amount);
        }

        this.total_cashin += amount;
      });
      this.chartData[1][1] = this.total_cashin;
      this.data_loaded_in = true;
    },

    getChartsValue: function () {
      this.fetch_cash_in();
      this.fetch_cash_out();
    },
  },
  created() {
    this.fetch_cash_in();
    this.fetch_cash_out();
  },
};
</script>
